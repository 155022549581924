import request from '@/utils/request'

export function fetchList(data) {
    return request({
        url: '/admin/logisticsInformation/list',
        method: 'post',
        data
    })
}


export function followUpList(data) {
    return request({
        url: '/admin/logisticsInformation/followUpList',
        method: 'get',
        params: data
    })
}

export function listByLogisticsInfo(data) {
    return request({
        url: '/admin/logisticsInformation/listByLogisticsInfo',
        method: 'get',
        params: data
    })
}

export function listByNumber(data) {
    return request({
        url: '/admin/logisticsInformation/listByNumber',
        method: 'get',
        params: data
    })
}

export function listByInvoiceId(data) {
    return request({
        url: '/admin/logisticsInformation/listByInvoiceId',
        method: 'get',
        params: data
    })
}

export function exportData(data) {
    return request({
        url: '/admin/logisticsInformation/export',
        method: 'post',
        data
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/admin/logisticsCompany/saveOrUpdate',
        method: 'post',
        data
    })
}

export function selectList() {
    return request({
        url: '/admin/logisticsCompany/selectList',
        method: 'get'
    })
}


export function selectAll() {
    return request({
        url: '/admin/logisticsCompany/selectAll',
        method: 'get'
    })
}

export function deleteLogisticsInformation(id) {
  return request({
    url: '/admin/logisticsInformation/delete',
    method: 'post',
    params: { id }
  })
}

export function selectGroup(status, fieldName) {
  return request({
    url: '/admin/logisticsInformation/selectGroup',
    method: 'get',
	params: { status , fieldName }
  })
}
