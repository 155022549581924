<template>
  <div class="pass-table">
		<el-popover placement="left" trigger="hover" class="popover" popper-class="popper" width="200" @show="showPopover">
			<div class="header">
				<el-checkbox v-model="checkAll" @change="checkAllChangeFn">列展示</el-checkbox>
				<el-button type="text" @click="reset(true)">重置</el-button>
			</div>
			<el-divider></el-divider>
			<el-checkbox-group v-model="checkColumns" @change="changeColumns">
				<el-checkbox v-for="(item, key) in columns" :key="key" :label="item.label"></el-checkbox>
			</el-checkbox-group>
			<el-button type="primary" icon="el-icon-s-tools" slot="reference">设置列</el-button>
		</el-popover>
    <div class="table-wrapper">
      <el-table class="main-table" :data="tableData" border header-row-class-name="table-header"
                highlight-current-row ref="filterTable" v-loading="loading">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="序号" v-if="showColumn('index')" prop="index" type="index" align="center" width="55" fixed></el-table-column>
        <el-table-column label="地区" v-if="showColumn('region')" prop="region" align="center"></el-table-column>
        <el-table-column label="类型" v-if="showColumn('sparePartsTypeName')" prop="sparePartsTypeName" align="center" width="120"></el-table-column>
        <el-table-column label="导入日期" v-if="showColumn('orderEntryDate')" prop="orderEntryDate" align="center" width="110" fixed></el-table-column>
        <el-table-column label="录入日期" v-if="showColumn('entryDate')" prop="entryDate" align="center" width="110" fixed>
          <template slot="header" slot-scope="{}">
            <el-popover placement="bottom" trigger="click" class="custom-header">
              <el-date-picker class="screen-time" v-model="screenTime" type="date" placeholder="选择录入日期"
                              value-format="yyyy-MM-dd" @change="screenTimeChange">
              </el-date-picker>
              <div class="title" slot="reference">录入日期<i class="el-icon-arrow-down"></i></div>
            </el-popover>
          </template>
          <template slot-scope="{row}">
            {{ row.entryDate }}
          </template>
        </el-table-column>
        <el-table-column label="提货单编号" v-if="showColumn('orderNum')" prop="orderNum" align="center" width="150" fixed></el-table-column>
        <el-table-column label="单位简称" v-if="showColumn('unitName')" prop="unitName" align="center" width="120"></el-table-column>
        <el-table-column label="提货金额（元）" v-if="showColumn('totalAmount')" prop="totalAmount" align="center" width="150"></el-table-column>
        <el-table-column label="提货件数" v-if="showColumn('totalNum')" prop="totalNum" align="center" width="150">
          <template slot-scope="{row}">
            {{ row.totalNum }}
            <el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态" v-if="showColumn('status')" prop="status" align="center" width="120">
          <template slot-scope="{row}">
            <el-tag v-if="row.status== -1">未提交</el-tag>
            <el-tag type="info" v-if="row.status==1">待审核</el-tag>
            <el-tag type="success" v-if="row.status==2">已通过</el-tag>
            <el-tag type="danger" v-if="row.status==3">未通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="不通过原因" v-if="showColumn('notPassReason')" prop="notPassReason" align="center" width="120"></el-table-column>
        <el-table-column label="运单号" v-if="showColumn('trackingNumber')" prop="trackingNumber" align="center" width="140"></el-table-column>
        <el-table-column label="计费方式" v-if="showColumn('billingMethod')" prop="billingMethod" align="center" width="120">
          <template slot-scope="{row}">
            <el-tag type="info" v-if="row.billingMethod==1">重量</el-tag>
            <el-tag type="info" v-if="row.billingMethod==2">体积</el-tag>
            <el-tag type="info" v-if="row.billingMethod==3">发货件数</el-tag>
            <el-tag type="info" v-if="row.billingMethod==4">重抛</el-tag>
            <el-tag type="info" v-if="row.billingMethod==5">整车</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货件数" v-if="showColumn('num')" prop="num" align="center" width="100"></el-table-column>
        <el-table-column label="重量（kg）" v-if="showColumn('weight')" prop="weight" align="center" width="120"></el-table-column>
        <el-table-column label="体积（m³）" v-if="showColumn('volume')" prop="volume" align="center" width="120"></el-table-column>
        <el-table-column label="运费（元）" v-if="showColumn('freight')" prop="freight" align="center" width="120"></el-table-column>
        <el-table-column label="备注" v-if="showColumn('remark')" prop="remark" align="center" width="120"></el-table-column>
        <el-table-column label="备注2" v-if="showColumn('remark2')" prop="remark2" align="center" width="120"></el-table-column>
        <el-table-column label="收货人" v-if="showColumn('receiver')" prop="receiver" align="center" width="100"></el-table-column>
        <el-table-column label="收货人电话" v-if="showColumn('receiverPhone')" prop="receiverPhone" align="center" width="140"></el-table-column>
        <el-table-column label="发出时间" v-if="showColumn('deliveryDate')" prop="deliveryDate" align="center" width="110"></el-table-column>
        <el-table-column label="收货地" v-if="showColumn('address')" prop="address" align="center" width="200"></el-table-column>
        <el-table-column label="物流公司" v-if="showColumn('logisticsCompanyName')" prop="logisticsCompanyName" align="center" width="120"
                         :filters="companyArr" :filter-method="filterCompany" filter-placement="bottom-end">
        </el-table-column>
        <el-table-column label="物流电话" v-if="showColumn('logisticsCompanyPhone')" prop="logisticsCompanyPhone" align="center" width="150"></el-table-column>
        <el-table-column label="免运费" v-if="showColumn('isFree')" prop="isFree" width="120" align="center" :filters="isFreeArr"
                         :filter-method="filterFreight" filter-placement="bottom-end">
          <template slot-scope="{row}">
            <el-tag v-if="row.isFree==1">是</el-tag>
            <el-tag type="danger" v-if="row.isFree==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货员" v-if="showColumn('consignorName')" prop="consignorName" align="center" width="120" :filters="consignorArr"
                         :filter-method="filterConsignor" filter-placement="bottom-end"></el-table-column>
        <el-table-column label="送货上门" v-if="showColumn('isVisit')" prop="isVisit" align="center" width="140" :filters="isVisitArr"
                         :filter-method="filterDeliver" filter-placement="bottom-end">
          <template slot-scope="{row}">
            <el-tag v-if="row.isVisit==1">是</el-tag>
            <el-tag type="danger" v-if="row.isVisit==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="物流单照片" v-if="showColumn('imgUrl')" prop="imgUrl" align="center" width="120">
          <template slot-scope="{row}">
            <el-image style="width:23px; height:23px" :src="row.imgUrl"
                      :preview-src-list="row.imgList.map(e => e.url)">
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="reset" type="warning" @click="resetClick" v-if="resetShow">重置筛选</el-button>
    </div>
    <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
                   :page-size="query.pagesize" @current-change="getList"></el-pagination>
    <el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
      <div class="picking-header">
        <div class="picking-title">提货通知单号：{{ pickingData.orderNum }}</div>
        <div class="picking-title">总价：{{ pickingData.totalAmount }}元</div>
      </div>
      <el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
                highlight-current-row>
        <el-table-column property="sparePartCode" label="备件代码"></el-table-column>
        <el-table-column property="sparePartName" label="备件名称"></el-table-column>
        <el-table-column property="num" label="数量"></el-table-column>
        <el-table-column property="costPrice" label="单价"></el-table-column>
        <el-table-column property="amount" label="金额"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import {
  fetchList,
  selectGroup
} from "@/api/logisticsInformation"

export default {
  props: ['searchInput', 'refresh'],
  data() {
    return {
      resetShow: false,
      screenTime: '',
      pickingData: "",
      dialogTableVisible: false,
      tableData: [],
			columns: [
				{ label: '序号', prop: 'index', isShow: true },
				{ label: '地区', prop: 'region', isShow: true },
				{ label: '类型', prop: 'sparePartsTypeName', isShow: true },
				{ label: '导入日期', prop: 'orderEntryDate', isShow: true },
				{ label: '录入日期', prop: 'entryDate', isShow: true },
				{ label: '提货单编号', prop: 'orderNum', isShow: true },
				{ label: '单位简称', prop: 'unitName', isShow: true },
				{ label: '提货金额（元）', prop: 'totalAmount', isShow: true },
				{ label: '提货件数', prop: 'totalNum', isShow: true },
				{ label: '状态', prop: 'status', isShow: true },
				{ label: '不通过原因', prop: 'notPassReason', isShow: true },
				{ label: '运单号', prop: 'trackingNumber', isShow: true },
				{ label: '计费方式', prop: 'billingMethod', isShow: true },
				{ label: '发货件数', prop: 'num', isShow: true },
				{ label: '重量（kg）', prop: 'weight', isShow: true },
				{ label: '体积（m³）', prop: 'volume', isShow: true },
				{ label: '运费（元）', prop: 'freight', isShow: true },
				{ label: '备注', prop: 'remark', isShow: true },
				{ label: '备注2', prop: 'remark2', isShow: true },
				{ label: '收货人', prop: 'receiver', isShow: true },
				{ label: '收货人电话', prop: 'receiverPhone', isShow: true },
				{ label: '发出时间', prop: 'deliveryDate', isShow: true },
				{ label: '收货地', prop: 'address', isShow: true },
				{ label: '物流公司', prop: 'logisticsCompanyName', isShow: true },
				{ label: '物流电话', prop: 'logisticsCompanyPhone', isShow: true },
				{ label: '免运费', prop: 'isFree', isShow: true },
				{ label: '发货员', prop: 'consignorName', isShow: true },
				{ label: '送货上门', prop: 'isVisit', isShow: true },
				{ label: '物流单照片', prop: 'imgUrl', isShow: true }
			],
			checkColumns: [],
			checkAll: false,
      total: 10,
      query: {
        status: 0,
        entryDate: '',
        keyword: '',
        page: 1,
        pagesize: 10,
        sort: '-entry_date,-unit_name,+order_num',
        isVisitArr: [],
        isFreeArr: [],
        companyArr: [],
        consignorArr: []
      },
      isVisitArr: [],
      isFreeArr: [],
      companyArr: [],
      consignorArr: [],
      isVisitArrWatch: [],
      isFreeArrWatch: [],
      companyArrWatch: [],
      consignorArrWatch: []


    }
  },
  mounted() {
    this.getGroup()
    this.getList()
    this.resetShow = true
		this.initColumnsSetting() 
  },
  watch: {
    isFreeArrWatch() {
      this.query.page = 1
      this.getList()
    },
    companyArrWatch() {
      this.query.page = 1
      this.getList()
    },
    isVisitArrWatch() {
      this.query.page = 1
      this.getList()
    },
    consignorArrWatch() {
      this.query.page = 1
      this.getList()
    },
    refresh() {
      if (this.refresh == "0") {
        this.getList()
      }
    },
    searchInput() {
      this.query.page = 1
      this.query.keyword = this.searchInput
      this.getList()
    },
    screenTime() {
      this.query.page = 1
      this.query.entryDate = this.screenTime
      this.getList()
    },
    tableData: {
      immediate: true,
      handler(val) {
        if (val.length == 0) {
          this.resetShow = true
        } else {
          this.resetShow = false
        }
      }

    }

  },
  methods: {
		// table列存到本地
		storageColumns() {
			localStorage.setItem('allColumns', JSON.stringify(this.columns));
		},
		// 初始化table列
		initColumnsSetting() {
			// 获取columns存储信息
			let columns = localStorage.getItem('allColumns');
			columns =JSON.parse(columns)
			if (columns) {
				this.columns = columns
				// 判断是否全选
				this.judgeIsCheckAll();
				this.refreshTable();
			}
		},
		// 全选复选框事件监听
		checkAllChangeFn(val) {
			if (val) {
					// 全选
					this.reset(true);
			} else {
					// 反全选
					this.reset(false);
			}
		},
		// 重置，flag: Boolean，全部重置为flag
		reset(flag) {
			this.columns.forEach(item => {
					item.isShow = flag;
			})
			this.showPopover();
			this.refreshTable();
			this.storageColumns()
		},
		// 表格列是否显示的方法
		showColumn(currentColumn) {
				return this.columns.find(item => item.prop == currentColumn).isShow;
		},
		/* 选择列 */
		changeColumns(val) {
			this.columns.forEach(item => {
					item.isShow = false;
			})
			// columns将val数组存在的值设为true，不存在的设为false
			val?.forEach(item => {
					let current = this.columns.find(i => i.label == item)
					current.isShow = true;
			})
			// 判断是否全选
			this.judgeIsCheckAll();
			this.refreshTable();
			this.storageColumns()
		},
		// 重新渲染表格
		refreshTable() {
			this.$nextTick(() => {
					this.$refs.filterTable.doLayout();
			})
		},
		// 气泡框出现
		showPopover() {
				this.checkColumns = []
				this.columns.forEach(item => {
						if (item.isShow) {
								this.checkColumns.push(item.label)
						}
				})
				// 判断是否全选
				this.judgeIsCheckAll();
		},
		// 判断是否全选
		judgeIsCheckAll() {
				// 选中的长度 = 表格列的长度  全选按钮就选中
				if (this.checkColumns.length == this.columns.length)
						this.checkAll = true
				else
						this.checkAll = false
		},
    resetClick() {
      this.query = {
        status: 0,
        entryDate: '',
        keyword: '',
        page: 1,
        pagesize: 10,
        isVisitArr: [],
        isFreeArr: [],
        companyArr: [],
        consignorArr: []
      },
          this.$refs.filterTable.clearFilter();
      this.getList()
      this.resetShow = false
    },
    getGroup() {
      selectGroup(this.query.status, 'is_visit').then(res => {
        this.isVisitArr = res.data
        this.query.isVisitArr = this.isVisitArr.map(e => e.value)
      })
      selectGroup(this.query.status, 'consignor_id').then(res => {
        this.consignorArr = res.data
        this.query.consignorArr = this.consignorArr.map(e => e.value)
      })
      selectGroup(this.query.status, 'logistics_company_id').then(res => {
        this.companyArr = res.data
        this.query.companyArr = this.companyArr.map(e => e.value)

      })
      selectGroup(this.query.status, 'is_free').then(res => {
        this.isFreeArr = res.data
        this.query.isFreeArr = this.isFreeArr.map(e => e.value)
      })
    },
    screenTimeChange() {
      this.query.entryDate = this.screenTime
      this.getList()
    },
    pickingDetails(row) {
      this.dialogTableVisible = true
      this.pickingData = row
      // console.log(this.pickingData)
    },
    getList() {
      this.loading = true
      fetchList(this.query).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    filterConsignor(value, row, column) {
      this.query.consignorArr = column.filteredValue
      this.consignorArrWatch = column.filteredValue
      return row.consignorId === value;
    },
    filterCompany(value, row, column) {
      this.query.companyArr = column.filteredValue
      this.companyArrWatch = column.filteredValue
      return row.logisticsCompanyId === value;

    },
    filterDeliver(value, row, column) {
      this.query.isVisitArr = column.filteredValue
      this.isVisitArrWatch = column.filteredValue
      return row.isVisit === value;
    },
    filterFreight(value, row, column) {
      this.query.isFreeArr = column.filteredValue
      this.isFreeArrWatch = column.filteredValue
      return row.isFree === value;
    },
    oneExamine() {
      this.$confirm("确定要全部审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "审核通过!",
            });
          })
          .catch(() => {
          });
    },
    examineClick() {
      this.$confirm("请确定审核是否通过?", "提示", {
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "审核通过!",
            });
          })
          .catch(() => {
            this.$prompt('请输入不通过原因', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /\S/,
              inputErrorMessage: '请输入不通过原因'
            }).then(() => {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
            }).catch(() => {
              // this.$message({
              // 	type: 'info',
              // 	message: '取消输入'
              // });
            });
          });
    },
    deleteClick(row) {
      this.$confirm("确定要永久删除该行数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.tableData.splice(row, 1)
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
          });
    },
  }
}
</script>
<style lang="scss" scoped>
.pass-table{
	position: relative;
	.popover{
		position: absolute;
		right: 0;
		top:-60px;
		.el-popover__reference{
			border-radius: 0;
		}
	}
}
.popper{
	.header{
		display: flex;
		justify-content:space-between;
		align-items: center;
	}
	/* 分割线 */
	.el-divider {
		margin:0 0 10px 0!important;
	}
	/* 复选框 */
	.el-checkbox-group {
		display: flex;
		flex-direction: column;
		max-height: 300px;
		overflow-y: auto;
	}
}
.el-table-filter {
  .el-table-filter__bottom {
    button {
      width: 100%;
      border: 1px solid #C0C4CC;
      border-radius: 3px;

      &:last-child {
        display: none !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.pass-table {
  .bottom {
    background-color: rgba($color: $main-color, $alpha: 0.1);
    padding: 5px 10px;
    text-align: right;

    .examine-btn {
      border-radius: 5px;
      font-size: 16px;
    }
  }

}
</style>
