<template>
	<div>
		<el-table class="main-table expand-table" :data="tableData" border header-row-class-name="table-header"
			@sort-change="handleSortChange" highlight-current-row ref="editTable" v-loading="loading" :row-class-name="tableRowClassName">
			<el-table-column type="selection" width="40"></el-table-column>
			<el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
			<el-table-column label="类型" prop="sparePartsTypeName" align="center" sortable="custom" width="120"
				column-key="type" :filters="typeList" :filter-method="Filtertype"></el-table-column>
			<el-table-column label="导入日期" prop="entryDate" align="center"></el-table-column>
			<el-table-column label="提货通知单编号" prop="deliveryNotice" align="center" width="160"></el-table-column>
			<el-table-column label="提货单编号" prop="orderNum" align="center"></el-table-column>
      <el-table-column label="地区" prop="region" align="center" width="80"></el-table-column>
      <el-table-column label="不通过原因" prop="notPassReason" align="center" width="120"></el-table-column>
			<el-table-column label="单位简称" prop="unitName" align="center"></el-table-column>
			<el-table-column label="提货金额（元）" prop="totalAmount" align="center"></el-table-column>
			<el-table-column label="备注" prop="remark"></el-table-column>
			<el-table-column label="物流单备注" prop="logisticsRemark" align="center">
				<template slot-scope="{row}">
					{{proportionRemark(row)}}
				</template>
			</el-table-column>
			<el-table-column label="运费占比" align="center" width="200">
				<template slot-scope="{row}">
					{{proportion(row)}}%
					<el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="300" align="center" class-name="table-operate">
				<template slot-scope="{row}">
					<el-button type="primary" size="mini" round @click="toogleExpand(row)">查看物流单</el-button>
					<el-button type="warning" size="mini" round v-if="common.canAudit()" @click="examineClick(row)">审核
					</el-button>
					<el-button type="warning" size="mini" round v-if="common.canEdit()" @click="revoke(row)">撤回
					</el-button>
				</template>
			</el-table-column>
			<el-table-column type="expand" width="1">
				<template slot-scope="{row}">
					<div class="input-content">
						<div class="input-content-row">
							<div class="column column01">运单号:</div>
							<div class="column column02">计费方式:</div>
							<div class="column column02">重量(kg):</div>
							<div class="column column03">体积(m³):</div>
							<div class="column column14">发货件数:</div>
							<div class="column column16">备注:</div>
							<div class="column column16">备注2:</div>
							<div class="column column04">运费(元):</div>
							<div class="column column05">收货人:</div>
							<div class="column column06">收货人电话:</div>
							<div class="column column07">发出时间:</div>
							<div class="column column08">收货地:</div>
							<div class="column column09">物流公司:</div>
							<div class="column column10">免运费:</div>
							<div class="column column11">发货员:</div>
							<div class="column column12">送货上门:</div>
							<div class="column column13">物流单照片:</div>
							<!-- <div class="column column14">不通过原因:</div> -->
							<!-- <div class="column column15">操作:</div> -->
						</div>
						<div class="input-content-row" v-for="(item,index) in row.logisticsInformations" :key="index">
							<div class="column column01">
								<el-input v-model="item.trackingNumber" readonly="true"></el-input>
							</div>
							<div class="column column02 billing-method">
								<el-select v-model="item.billingMethod" disabled placeholder="请选择">
									<el-option v-for="item in billingMethodOptions" :key="item.value"
										:label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="column column02">
								<el-input type="number" v-model="item.weight" readonly="true"></el-input>
							</div>
							<div class="column column03">
								<el-input type="number" v-model="item.volume" readonly="true"></el-input>
							</div>
							<div class="column column14">
								<el-input type="number" v-model="item.num" readonly="true"></el-input>
							</div>
							<div class="column column16">
								<el-input v-model="item.remark" readonly="true"></el-input>
							</div>
							<div class="column column16">
								<el-input v-model="item.remark2" readonly="true"></el-input>
							</div>
							<div class="column column04">
								<el-input type="number" v-model="item.freight" readonly="true"></el-input>
							</div>
							<div class="column column05">
								<el-input v-model="item.receiver" readonly="true"></el-input>
							</div>
							<div class="column column06">
								<el-input v-model="item.receiverPhone" readonly="true"></el-input>
							</div>
							<div class="column column07">
								<el-date-picker v-model="item.deliveryDate" readonly="true" type="date">
								</el-date-picker>
							</div>
							<div class="column column08">
								<el-input v-model="item.address" readonly="true"></el-input>
							</div>
							<div class="column column09">
								<el-select v-model="item.logisticsCompanyId" disabled>
									<el-option v-for="item in logisticsCompanyOptions" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="column column10">
								<el-select v-model="item.isFree" disabled>
									<el-option v-for="item in freightOptions" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="column column11">
								<el-select v-model="item.consignorId" disabled>
									<el-option v-for="item in consignorOptions" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="column column12">
								<el-select v-model="item.isVisit" disabled>
									<el-option v-for="item in deliverOptions" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="column column13">

								<el-image style="width:23px; height:23px" :src="item.imgUrl"
									:preview-src-list="item.imgList.map(e => e.url)">
								</el-image>

							</div>
							<!-- <div class="column column14">
								<el-input type="textarea" autosize placeholder="请输入不通过原因" v-model="item.reason">
								</el-input>
							</div> -->
							<!-- 	<div class="column column15">
								<el-button class="add-btn" type="primary" size="mini" icon="el-icon-plus" circle
									v-if="index==0" @click="expandAddClick(row,index)"></el-button>
								<el-button class="delete-btn" type="danger" size="mini" icon="el-icon-delete" circle
									v-if="index!=0" @click="expandDelClick(row,index)"></el-button>
							</div> -->
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom" v-if="common.canAudit()">
			<el-button class="examine-btn" type="primary" size="small" round @click="oneExamine()">一键审核通过</el-button>
		</div>
		<el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
			:page-size="query.pagesize" @current-change="getList"></el-pagination>
		<el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
			<div class="picking-header">
				<div class="picking-title">提货通知单号：{{pickingData.orderNum}}</div>
				<div class="picking-title">总价：{{pickingData.totalAmount}}元</div>
			</div>
			<el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
				highlight-current-row>
				<el-table-column property="sparePartCode" label="备件代码"></el-table-column>
				<el-table-column property="sparePartName" label="备件名称"></el-table-column>
				<el-table-column property="num" label="数量"></el-table-column>
				<el-table-column property="costPrice" label="单价"></el-table-column>
				<el-table-column property="amount" label="金额"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		regionDataPlus
	} from 'element-china-area-data'
	import {
		fetchList,
		audit,
		revoke,
		selectSparePartsType
	} from "@/api/invoice";
	import {
		selectAll as selectConsignorList
	} from "@/api/consignor"
	import {
		selectAll as selectLogisticsCompanyList
	} from "@/api/logisticsInformation"

	export default {
		props: ['searchInput', 'refresh', 'isFree'],
		data() {
			return {
        loading: false,
				billingMethodOptions: [{
					value: 1,
					label: '重量'
				}, {
					value: 2,
					label: '体积'
				}, {
					value: 3,
					label: '发货件数'
				}, {
					value: 4,
					label: '重抛'
				}, {
					value: 5,
					label: '整车'
				}],
				pickingData: "",
				dialogTableVisible: false,
				typeList: [],
				ruleForm: {
					imgList: null
				},
				rules: {
					imgList: [{
						required: true,
						message: '请上传图片',
						trigger: 'change'
					}]
				},
				regionOptions: regionDataPlus,
				deliverOptions: [{
					value: 1,
					label: '是'
				}, {
					value: 2,
					label: '否'
				}],
				consignorOptions: [],
				freightOptions: [{
					value: 1,
					label: '是'
				}, {
					value: 2,
					label: '否'
				}],
				logisticsCompanyOptions: [],
				tableData: [],
				total: 10,
				sparePartsTypes: [],
				query: {
					status: 1,
					keyword: '',
					page: 1,
					pagesize: 10,
					isFree: 1,
					sortProp: '',
					sortOrder: '',
					sort: '-update_time',
					sparePartsTypes: []
				}
			}
		},
		watch: {
			refresh() {
				if (this.refresh == "1") {
					this.getList()
				}
			},
			isFree() {
				if (this.isFree == 0) {
					this.tableData = []
					this.total = 0
					this.loading=false
				} else {
					this.query.isFree = this.isFree
					this.getList()
				}

			},
			searchInput() {
				this.query.page = 1
				this.query.keyword = this.searchInput
				this.getList()
			},

			sparePartsTypes() {
				this.query.page = 1
				this.query.sparePartsTypes = this.sparePartsTypes

				this.$emit('sparePartsTypes', this.sparePartsTypes)
				this.getList()
			}
		},
		mounted() {
			this.getList()
			this.typeScreen()
			this.getConsignorList()
			this.getLogisticsCompanyList()
			this.selectSparePartsType()
		},
		methods: {
      // eslint-disable-next-line no-unused-vars
      tableRowClassName({row, rowIndex}) {
        if (row.isFree === 2) {
          return 'success-row';
        }
        if(row.notPassReason !== null && row.notPassReason !== ''){
          return 'error-row';
        }
        return '';

    },

    proportionRemark(row) {
				let newLogisticsRemark = ''
				let logisticsRemark = row.logisticsRemark
				if (logisticsRemark.length > 0) {
					for (let i in logisticsRemark) {
						newLogisticsRemark += (parseInt(i) + 1) + '.' + logisticsRemark[i] + '\n'
					}
				}
				return newLogisticsRemark;
			},
			proportion(row) {
				let freightTotal = 0
				row.logisticsInformations.forEach((item) => {
					freightTotal += item.freight
				})
				return (freightTotal / row.totalAmount * 100).toFixed(2)
			},
			revoke(row) {
				revoke(row.id).then(res => {
					if (res.code == 0) {
						this.$message({
							type: "success",
							message: "撤回成功!",
						});
						this.getList()
					}
				})
			},
			pickingDetails(row) {
				this.dialogTableVisible = true
				this.pickingData = row
				console.log(this.pickingData)
			},
			selectSparePartsType() {
				selectSparePartsType(this.query.status).then(res => {
					this.typeList = res.data
				})
			},
			oneExamine() {
				let examineData = this.$refs.editTable.selection
				let ids = []
				for (let i in examineData) {
					ids.push(examineData[i].id)

				}
				if (ids.length <= 0) {
					this.$message({
						type: "error",
						message: "请选择要审核的数据!",
					});
					return
				}
				let auditPost = {
					ids: ids,
					status: 2,
					notPassReason: ''
				}
				audit(auditPost).then(res => {
					if (res.code === 0) {
						this.$message({
							type: "success",
							message: "审核通过!",
						});
						this.getList()
					}
				})
			},
			examineClick(row) {
				this.$confirm("请确定审核是否通过?", "提示", {
						confirmButtonText: "通过",
						cancelButtonText: "不通过",
						type: "warning",
					})
					.then(() => {
						let data = {
							ids: [row.id],
							status: 2,
							notPassReason: row.notPassReason
						}
						audit(data).then(res => {
							if (res.code === 0) {
								this.$message({
									type: "success",
									message: "审核通过!",
								});
								this.getList()
							}
						})


					})
					.catch(() => {
						this.$prompt('请输入不通过原因', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							inputPattern: /\S/,
							inputErrorMessage: '请输入不通过原因'
						}).then(({
							value
						}) => {
							let data = {
								ids: [row.id],
								status: 3,
								notPassReason: value
							}
							audit(data).then(res => {
								if (res.code === 0) {
									this.$message({
										type: "success",
										message: "提交成功!",
									});
									this.getList()
								}
							})
						}).catch(() => {
							// this.$message({
							// 	type: 'info',
							// 	message: '取消输入'
							// });
						});
					});
			},
			getConsignorList() {
				selectConsignorList().then(res => {
					this.consignorOptions = res.data
				})
			},
			getLogisticsCompanyList() {
				selectLogisticsCompanyList().then(res => {
					this.logisticsCompanyOptions = res.data

				})
			},

			getList() {
				this.loading=true
				fetchList(this.query).then(res => {
					this.tableData = res.data.list
					this.total = res.data.total
					this.loading = false
				})
			},
			typeScreen() {
				let obj = {}
				for (var i = 0; i < this.tableData.length; i++) {
					if (!obj[this.tableData[i].type]) {
						this.typeList.push({
							text: this.tableData[i].type,
							value: this.tableData[i].type
						});
						console.log(this.tableData[i])
						obj[this.tableData[i].type] = true;
					}
				}
			},
			Filtertype(value, row, column) {
				this.sparePartsTypes = column.filteredValue
				return row.sparePartsType === value;
			},
			expandDelClick(row, index) {
				row.logisticsInformations.splice(index, 1)
			},
			handleChange(value) {
				console.log(value)
				// 楼主选择江苏省南京市江宁区 打印value结果 ["320000", "320100", "320114"]
			},
			// 展开
			toogleExpand(row) {
				let $editTable = this.$refs.editTable;
				$editTable.toggleRowExpansion(row)

			},

			handleSortChange(column, prop, order) {
				console.log(column, prop, order)
				//todo
			},
		}
	}
</script>

<style lang="scss">
	.bottom {
		background-color: rgba($color:$main-color, $alpha:0.1);
		padding: 5px 10px;
		text-align: right;

		.examine-btn {
			border-radius: 5px;
			font-size: 16px;
		}
	}


  .el-table .success-row {
    background: #a4ea7d;
  }

  .el-table .error-row {
    background: #f6fc51;
  }

</style>
