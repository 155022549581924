<template>
  <div class="wrapper home-page">
    <div class="home-page-top">
      <div class="left">
        <el-button :disabled="!common.canEdit()" type="primary" class="import-btn" @click="importClick">导入数据
        </el-button>
        <el-button :disabled="!common.canEdit()" type="primary" class="import-btn" @click="addClick">添加提货单
        </el-button>
      </div>
      <div class="right">
        <el-input class="search-input" placeholder="请输入关键字模糊搜索" v-model="searchInput" @input="searchChange">
          <el-button type="primary" slot="append" icon="el-icon-search" @click="search()"></el-button>
        </el-input>
        <el-button type="primary" v-if="activeName != -1" @click="exportExcel" class="export-btn">导出Excel表格
        </el-button>
      </div>
      <div class="choice" v-if="activeName == 1">
        <!-- 				<el-checkbox class="choice-all" border :indeterminate="isIndeterminateFreight" v-model="checkAllFreight" @change="FreightCheckAllChange">全选</el-checkbox>
 -->
        <el-checkbox-group v-model="checkedFreight" @change="CheckedFreightChange">
          <el-checkbox-button v-for="item in freightList" :label="item" :key="item">{{ item }}
          </el-checkbox-button>
        </el-checkbox-group>
      </div>
    </div>
    <!-- 切换按钮 -->
    <el-tabs class="main-tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="common.canEdit()" label="未编辑" name="-1">
        <editTable :searchInput="searchValue" :refresh="refresh"></editTable>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="1">
        <pendingReview :searchInput="searchValue" :isFree="isFree" :refresh="refresh"
                       @sparePartsType="sparePartsTypes">
        </pendingReview>
      </el-tab-pane>
      <el-tab-pane label="已通过" name="2">
        <passTable :searchInput="searchValue" :refresh="refresh" @appendTracking="appendTracking"></passTable>
      </el-tab-pane>
      <el-tab-pane label="未通过" name="3">
				<span slot="label">
					<el-badge :value="noPassTotal" class="item">
						未通过
					</el-badge>
				</span>
        <notPassTable :searchInput="searchValue" @total="getTotal" :refresh="refresh" @appendTracking="appendTracking">
        </notPassTable>
      </el-tab-pane>
      <el-tab-pane label="全部" name="0">
        <allTable :refresh="refresh" :searchInput="searchValue" :loading="loading"></allTable>
      </el-tab-pane>
    </el-tabs>
    <!-- 导入弹窗 -->
    <el-dialog title="导入数据" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="导入日期" required>
          <el-form-item prop="date">
            <el-date-picker type="date" placeholder="请选择导入日期" v-model="ruleForm.date" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" style="width: 100%;">
            </el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="导入数据" prop="fileList">
          <el-upload class="upload-demo" action="/api/admin/invoice/upload" ref="upload" :headers="headers"
                     :auto-upload="false" accept=".xlsx, .xls" multiple :data="{ entryDate: ruleForm.date }"
                     :on-change="fileChange" :on-remove="fileRemove" :on-success="handleSuccess"
                     :file-list="ruleForm.fileList" v-model="ruleForm.fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx格式文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog title="导出数据" :visible.sync="dialogExportVisible" :close-on-click-modal="false">
      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="选择日期范围">
          <el-date-picker v-model="dateRange" type="datetimerange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择导出列" prop="fileList">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
          </el-checkbox>
          <el-checkbox-group v-model="checkedHeaders" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in tHeaderBox" :label="item.key" :key="item.key">{{ item.lable }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="downloadLoading" @click="handleDownload">下载</el-button>
          <el-button @click="dialogExportVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 添加提货单 -->
    <el-dialog title="添加提货单" :visible.sync="dialogAddVisible" width="70%" v-if="dialogAddVisible"
               :close-on-click-modal="false">
      <el-form :model="addRuleForm" :rules="addRules" ref="addRuleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="提货通知单:" required>
          <el-col :span="12">
            <el-form-item prop="deliveryNotice">
              <el-input v-model="addRuleForm.deliveryNotice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="录入日期:" required>
              <el-form-item prop="entryDate">
                <el-date-picker type="date" placeholder="选择录入日期" v-model="addRuleForm.entryDate">
                </el-date-picker>
              </el-form-item>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="提货单编号:" required>
          <el-col :span="12">
            <el-form-item prop="orderNum">
              <el-input v-model="addRuleForm.orderNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位简称:" prop="unitName">
              <el-input v-model="addRuleForm.unitName"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="提货金额(元):" required>
          <el-col :span="12">
            <el-form-item prop="totalAmount">
              <el-input v-model.number="addRuleForm.totalAmount" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提货件数:" prop="totalNum">
              <el-input-number v-model="addRuleForm.totalNum"></el-input-number>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="提货明细:">
          <el-button type="primary" size="small" @click="detailsAdd">添加</el-button>
          <el-button :disabled="!common.canEdit()" size="small" type="primary" class="import-btn" @click="importClickDetail">导入数据
          </el-button>
          <el-table class="main-table" :data="addRuleForm.invoiceDetails" border
                    header-row-class-name="table-header mini-table-header" highlight-current-row>
            <el-table-column prop="sparePartCode" label="备件代码">
              <template slot-scope="scope">
                <el-form-item :prop="`invoiceDetails.${scope.$index}.sparePartCode`"
                              :rules="addRules.nonull">
                  <el-input v-model="scope.row.sparePartCode" placeholder="请输入"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="sparePartName" label="备件名称">
              <template slot-scope="scope">
                <el-form-item :prop="`invoiceDetails.${scope.$index}.sparePartName`"
                              :rules="addRules.nonull">
                  <el-input v-model="scope.row.sparePartName" placeholder="请输入"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="数量">
              <template slot-scope="scope">
                <el-form-item :prop="`invoiceDetails.${scope.$index}.num`" :rules="addRules.nonull">
                  <el-input-number v-model="scope.row.num"></el-input-number>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="costPrice" label="单价">
              <template slot-scope="scope">
                <el-form-item :prop="`invoiceDetails.${scope.$index}.costPrice`"
                              :rules="addRules.nonull">
                  <el-input v-model="scope.row.costPrice" placeholder="请输入" type="number"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="金额">
              <template slot-scope="scope">
                <el-form-item :prop="`invoiceDetails.${scope.$index}.amount`" :rules="addRules.nonull">
                  <el-input v-model="scope.row.amount" placeholder="请输入" type="number"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" align="center">
              <template slot-scope="scope">

                <el-button type="warning" size="small" @click="detailsDel(scope.row,scope.$index)">删除
                </el-button>
              </template>
            </el-table-column>

          </el-table>

        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmitForm('addRuleForm')">提交</el-button>
          <el-button @click="dialogAddVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="导入明细" :visible.sync="dialogFormVisibleDetail" :close-on-click-modal="false">
      <el-form :model="ruleFormDetail" :rules="rulesDetail" ref="ruleFormDetail" label-width="100px" class="demo-ruleForm">
        <el-form-item label="明细文件" prop="fileList">
          <el-upload class="upload-demo" action="/api/admin/invoice/uploadDetail" ref="uploadDetail" :headers="headers"
                     :auto-upload="false" accept=".xlsx, .xls" multiple  :data="{ entryDate: ruleForm.date }"
                     :on-change="fileChangeDetail" :on-remove="fileRemoveDetail" :on-success="handleSuccessDetail"
                     :file-list="ruleFormDetail.fileList" v-model="ruleFormDetail.fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls/xlsx格式文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitFormDetail('ruleFormDetail')">确 定</el-button>
          <el-button @click="dialogFormVisibleDetail = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import editTable from "./components/editTable.vue"
import passTable from "./components/passTable.vue"
import notPassTable from "./components/notPassTable.vue"
import allTable from "./components/allTable.vue"
import pendingReview from "./components/pendingReview.vue"
import {
  addInvoice
}
  from "@/api/invoice"
import {
  exportData
} from "@/api/logisticsInformation"

export default {
  components: {
    editTable,
    passTable,
    pendingReview,
    notPassTable,
    allTable

  },
  data() {
    return {
      noPassTotal: 0,
      checkAllFreight: false,
      checkedFreight: ['免运费'],
      freightList: ['免运费', '用户自费'],
      isIndeterminateFreight: true,
      dialogAddVisible: false,
      dateRange: null,
      addRuleForm: {
        deliveryNotice: '',
        entryDate: '',
        orderNum: '',
        unitName: '',
        totalAmount: '',
        totalNum: 1,
        invoiceDetails: [],
      },
      isFree: 0,
      addRules: {
        deliveryNotice: [{
          required: true,
          message: '请输入提货通知单编号',
          trigger: 'blur'
        }],
        entryDate: [{
          type: 'date',
          required: true,
          message: '请选择录入日期',
          trigger: 'change'
        }],
        orderNum: [{
          required: true,
          message: '请输入提货单编号',
          trigger: 'blur'
        }],
        unitName: [{
          required: true,
          message: '请输入单位简称',
          trigger: 'blur'
        }],
        totalAmount: [{
          required: true,
          message: '请输入提货金额',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字值',
          trigger: 'blur'
        },],
        totalNum: [{
          required: true,
          message: '请输入提货件数',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '必须为数字值',
          trigger: 'blur'
        }],
        nonull: [{
          required: true,
          trigger: 'blur'
        }],
      },
      // pendingLoading: false,
      loading: true,
      loadingUpload: null,
      loadingUploadDetail: null,
      checkAll: true,
      headers: {
        token: localStorage.getItem('token')
      },
      tHeaderBox: [{
        key: 'sparePartsTypeName',
        lable: '备品类型'
      }, {
        key: 'orderNum',
        lable: '提货单编号'
      }, {
        key: 'orderEntryDate',
        lable: '导入日期'
      }, {
        key: 'unitName',
        lable: '单位简称'
      }, {
        key: 'region',
        lable: '地区'
      }, {
        key: 'notPassReason',
        lable: '不通过原因'
      }, {
        key: 'totalAmount',
        lable: '提货金额'
      }, {
        key: 'address',
        lable: '收货人地址'
      }, {
        key: 'receiver',
        lable: '收货人姓名'
      }, {
        key: 'receiverPhone',
        lable: '收货人电话'
      }, {
        key: 'num',
        lable: '发货件数'
      }, {
        key: 'logisticsCompanyName',
        lable: '发货物流'
      }, {
        key: 'trackingNumber',
        lable: '运单号'
      }, {
        key: 'logisticsCompanyPhone',
        lable: '物流电话'
      }, {
        key: 'deliveryDate',
        lable: '发出时间'
      }, {
        key: 'freight',
        lable: '运费'
      }, {
        key: 'isFree',
        lable: '是否免费'
      }, {
        key: 'consignorName',
        lable: '发货人'
      }, {
        key: 'weight',
        lable: '重量'
      }, {
        key: 'volume',
        lable: '体积'
      }, {
        key: 'billingMethod',
        lable: '计费方式'
      }, {
        key: 'remark',
        lable: '备注'
      }, {
        key: 'remark2',
        lable: '备注2'
      }, {
        key: 'isVisit',
        lable: '是否送货上门'
      }, {
        key: 'status',
        lable: '状态'
      }, {
        key: 'entryDate',
        lable: '录入日期'
      }],
      isIndeterminate: false,
      searchInput: '',
      searchValue: '',
      activeName: '-1',
      dialogFormVisible: false,
      dialogFormVisibleDetail: false,
      dialogExportVisible: false,
      ruleForm: {
        date: '',
        fileList: []
      },
      ruleFormDetail: {
        fileList: []
      },
      checkedHeaders: [],
      rules: {
        date: [{
          required: true,
          message: '请选择日期',
          trigger: 'change'
        }],
        fileList: [{
          required: true,
          message: '请导入数据',
          trigger: 'change'
        }]
      },
      rulesDetail: {
        fileList: [{
          required: true,
          message: '请导入数据',
          trigger: 'change'
        }]
      },
      succFiles: [],
      succFilesDetail: [],
      downloadLoading: false,
      sparePartsTypes: [],
      refresh: '0',
      query: {
        status: 0,
        keyword: '',
        page: 1,
        pagesize: 1000000,
        sort: '-entry_date,-unit_name,+order_num',
        sparePartsTypes: [],
        startDate: null,
        endDate: null
      }
    }
  },
  mounted() {
    this.handleCheckAllChange(true)
    if (this.common.canAudit()) {
      this.activeName = '1'
    }
  },
  methods: {
    getTotal(data) {
      this.noPassTotal = data
    },
    searchChange() {
      this.loading = false
    },
    CheckedFreightChange(value) {
      this.loading = true
      let checkedCount = value.length;
      if (checkedCount == 0) {
        this.isFree = 0
      } else if (checkedCount == 1) {
        this.isFree = value[0] == '免运费' ? 1 : 2
      } else if (checkedCount == 2) {
        this.isFree = 4
      }
      // this.checkAllFreight = checkedCount === this.freightList.length;
      // this.isIndeterminateFreight = checkedCount > 0 && checkedCount < this.freightList.length;
    },
    detailsAdd() {
      this.addRuleForm.invoiceDetails.push({
        sparePartCode: '',
        sparePartName: '',
        num: 1,
        costPrice: '',
        amount: ''
      })
    },
    detailsDel(row, index) {
      this.addRuleForm.invoiceDetails.splice(index, 1);
    },
    addSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addInvoice(this.addRuleForm).then(res => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
              this.dialogAddVisible = false
              this.refresh = '0'
              this.activeName = '-1'
              this.searchInput = res.data.orderNum
              this.search()
            }

          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    addClick() {
      this.addRuleForm = {
        deliveryNotice: '',
        entryDate: '',
        orderNum: '',
        unitName: '',
        totalAmount: '',
        totalNum: 1,
        invoiceDetails: []
      }
      this.dialogAddVisible = true

    },
    handleCheckAllChange(val) {
      this.checkedHeaders = val ? this.tHeaderBox.map(e => e.key) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tHeaderBox.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tHeaderBox.length;
    },
    exportExcel() {
      this.dialogExportVisible = true
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出Excel表格，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      if (this.activeName == '1') {
        this.query.status = 1
        this.query.sparePartsTypes = this.sparePartsTypes
      } else if (this.activeName == "2") {
        this.query.status = 2
        this.query.sparePartsTypes = []
      } else if (this.activeName == "3") {
        this.query.status = 3
        this.query.sparePartsTypes = []
      } else if (this.activeName == "0") {
        this.query.status = 0
        this.query.sparePartsTypes = []
      }
      this.query.keyword = this.searchInput

      this.downloadLoading = true
      let tHeader = []
      for (let i in this.checkedHeaders) {
        for (let j in this.tHeaderBox) {
          if (this.tHeaderBox[j].key === this.checkedHeaders[i]) {
            tHeader.push(this.tHeaderBox[j].lable)
          }
        }
      }
      if (this.dateRange != null) {
        this.query.startDate = this.dateRange[0]
        this.query.endDate = this.dateRange[1]
      }

      exportData(this.query).then(response => {
        import('@/vendor/Export2Excel').then(excel => {
          let list = response.data
          const data = this.formatJson(this.checkedHeaders, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: '物流明细单',
            autoWidth: true,
            bookType: 'xlsx'
          })
          this.downloadLoading = false
          loading.close();
          this.dialogExportVisible = false
        })
      })

    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {

        if (j === "isVisit") {
          return v[j] === 1 ? "是" : "否"
        }
        if (j === "isFree") {
          return v[j] === 1 ? "是" : "否"
        }

        if (j === 'status') {
          let text = ''
          switch (v[j]) {
            case -1:
              text = '未提交'
              break
            case 1:
              text = '待审核'
              break
            case 2:
              text = '已通过'
              break
            case 3:
              text = '未通过'
              break
          }
          return text
        }

        if (j === 'billingMethod') {
          let text = ''
          switch (v[j]) {
            case 1:
              text = '重量'
              break
            case 2:
              text = '体积'
              break
            case 3:
              text = '发货件数'
              break
            case 4:
              text = '重抛'
              break
            case 5:
              text = '整车'
              break
          }
          return text
        }

        return v[j]
      }))
    },
    appendTracking(orderNum) {
      this.searchInput = orderNum
      this.activeName = "-1"
      this.search()
    },
    sparePartsType(sparePartsTypes) {
      this.sparePartsTypes = sparePartsTypes
    },
    search() {
      this.searchValue = this.searchInput
    },
    fileChange(uploadFile, fileList) {
      this.ruleForm.fileList = uploadFile
      if (fileList.length !== 0) {
        this.$refs.ruleForm.validateField('fileList')
      }
    },
    fileRemove(uploadFile, fileList) {
      if (fileList.length === 0) {
        this.ruleForm.fileList = null
        this.$refs.ruleForm.validateField('fileList')
      }
    },
    handleSuccess(res, file, fileList) {
      if (res.code == 0) {
        this.$message.success('[' + file.name + '] 导入成功')
        this.loadingUpload.close();
        location.reload()
        this.succFiles.push(file)
      } else {
        this.loadingUpload.close();
        this.$message.error('[' + file.name + ']' + res.desc)
      }
      if (this.succFiles.length == fileList.length) {
        this.dialogFormVisible = false
      }
    },
    fileChangeDetail(uploadFile, fileList) {
      this.ruleFormDetail.fileList = uploadFile
      if (fileList.length !== 0) {
        this.$refs.ruleFormDetail.validateField('fileList')
      }
    },
    fileRemoveDetail(uploadFile, fileList) {
      if (fileList.length === 0) {
        this.ruleFormDetail.fileList = null
        this.$refs.ruleFormDetail.validateField('fileList')
      }
    },
    handleSuccessDetail(res, file, fileList) {
      if (res.code == 0) {
        this.$message.success('[' + file.name + '] 导入成功')
        this.addRuleForm.invoiceDetails = res.data
        this.loadingUploadDetail.close();
        //显示明细
        this.succFilesDetail.push(file)
      } else {
        this.loadingUploadDetail.close();
        this.ruleFormDetail.fileList = []
        this.$message.error('[' + file.name + ']' + '文件格式格式错误，请检查文件内同或打开文件重新保存后再导入！')
      }
      if (this.succFilesDetail.length == fileList.length) {
        this.dialogFormVisibleDetail = false
      }
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.loadingUpload = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.succFiles = []
          this.$refs.upload.submit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitFormDetail(ruleFormDetail) {
      this.$refs[ruleFormDetail].validate((valid) => {
        if (valid) {
          this.loadingUploadDetail = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.succFilesDetail = []
          this.$refs.uploadDetail.submit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    importClick() {
      this.loading = false
      this.ruleForm.date = this.common.today()
      this.ruleForm.fileList = []
      this.dialogFormVisible = true
    },
    importClickDetail() {
      this.ruleFormDetail.fileList = []
      this.dialogFormVisibleDetail = true
    },
    handleClick(tab) {
      console.log(tab)
      this.refresh = this.activeName
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/homepage.scss";
</style>
<style lang="scss" scoped>
::v-deep.main-tabs{
	.el-tabs__content{
		overflow: inherit;
	}
}
::v-deep.el-badge {
  height: 20px !important;
  line-height: 20px !important;
  margin-top: -5px !important;

  .el-badge__content {
    right: 15px;
  }
}
</style>
