import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/consignor/list',
        method: 'get',
        params: query
    })
}

export function selectList() {
    return request({
        url: '/admin/consignor/selectList',
        method: 'get'
    })
}

export function selectAll() {
    return request({
        url: '/admin/consignor/selectAll',
        method: 'get'
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/admin/consignor/saveOrUpdate',
        method: 'post',
        data
    })
}

export function deleteConsignor(id) {
  return request({
    url: '/admin/consignor/delete',
    method: 'get',
    params: { id }
  })
}