<template>
  <div>
    <el-table class="main-table expand-table" :data="tableData" border header-row-class-name="table-header"
              v-loading="loading" @sort-change="handleSortChange" highlight-current-row ref="editTable"
              :row-class-name="tableRowClassName">
      <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
      <el-table-column label="类型" prop="sparePartsTypeName" align="center" sortable="custom" width="120"
                       column-key="type" :filters="typeList" :filter-method="Filtertype"></el-table-column>
      <el-table-column label="导入日期" prop="entryDate" align="center" width="110">
        <template slot="header" slot-scope="{}">
          <el-popover placement="bottom" trigger="click" class="custom-header">
            <el-date-picker class="screen-time" v-model="screenTime" type="date" placeholder="选择导入日期"
                            value-format="yyyy-MM-dd" @change="screenTimeChange">
            </el-date-picker>
            <div class="title" slot="reference">导入日期<i class="el-icon-arrow-down"></i></div>
          </el-popover>
        </template>
        <template slot-scope="{row}">
          {{ row.entryDate }}
        </template>
      </el-table-column>
      <el-table-column label="提货通知单编号" prop="deliveryNotice" align="center" width="160"></el-table-column>
      <el-table-column label="提货单编号" prop="orderNum" align="center"></el-table-column>
      <el-table-column label="地区" prop="region" align="center" width="80"></el-table-column>
      <el-table-column label="不通过原因" prop="notPassReason" align="center" width="120"></el-table-column>
      <el-table-column label="单位简称" prop="unitName" align="center"></el-table-column>
      <el-table-column label="提货金额（元）" prop="totalAmount" align="center"></el-table-column>
      <el-table-column label="提货件数" prop="totalNum" align="center">
        <template slot-scope="{row}">
          {{ row.totalNum }}
          <el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作" width="300" align="center" class-name="table-operate">
        <template slot-scope="{row, $index}">
          <el-button type="primary" size="mini" round @click="toogleExpand(row)">录入信息</el-button>
          <el-button type="warning" size="mini" round @click="examineClick(row)">提交审核</el-button>
          <el-button type="danger" size="mini" round @click="deleteClick(row,$index)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="1">
        <template slot-scope="{row}">
          <div class="input-content">
            <div class="input-content-row">
              <div class="column column10">免运费:</div>
              <div class="column column01">运单号:</div>
              <div class="column column02">计费方式:</div>
              <div class="column column03">重量(kg):</div>
              <div class="column column03">体积(m³):</div>
              <div class="column column14">发货件数:</div>
              <div class="column column16">备注:</div>
              <div class="column column16">备注2:</div>
              <div class="column column04">运费(元):</div>
              <div class="column column05">收货人:</div>
              <div class="column column06">收货人电话:</div>
              <div class="column column07">发出时间:</div>
              <div class="column column08">收货地:</div>
              <div class="column column09">物流公司:</div>
              <div class="column column11">发货员:</div>
              <div class="column column12">送货上门:</div>
              <div class="column column13">物流单照片:</div>
              <div class="column column15">操作:</div>
            </div>
            <div class="input-content-row" v-for="(item,index) in row.logisticsInformations" :key="index">
              <div class="column column10">
                <el-select v-model="item.isFree" :disabled="checkStatus(item)" placeholder="请选择">
                  <el-option v-for="item in freightOptions" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="column column01">
                <el-input placeholder="请输入运单号" :disabled="checkStatus(item)"
                          v-model="item.trackingNumber"></el-input>
              </div>
              <div class="column column02 billing-method">
                <el-select v-model="item.billingMethod" :disabled="checkStatus(item)" placeholder="请选择">
                  <el-option v-for="item in billingMethodOptions" :key="item.value"
                             :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="column column03">
                <el-input placeholder="输入重量" :disabled="checkStatus(item)" v-model="item.weight"
                          @keyup.native="item.weight = numInput(item.weight)"></el-input>
              </div>
              <div class="column column03">
                <el-input placeholder="输入体积" :disabled="checkStatus(item)" v-model="item.volume"
                          @keyup.native="item.volume = numInput(item.volume)"></el-input>
              </div>
              <div class="column column14">
                <el-input-number v-model="item.num" :min="1" :disabled="checkStatus(item)">
                </el-input-number>
              </div>
              <div class="column column16">
                <el-input placeholder="请输入备注" v-model="item.remark" :disabled="checkStatus(item)">
                </el-input>
              </div>
              <div class="column column16">
                <el-input placeholder="请输入备注2" v-model="item.remark2" :disabled="checkStatus(item)">
                </el-input>
              </div>
              <div class="column column04">
                <el-input placeholder="请输入运费" :disabled="checkStatus(item)" v-model="item.freight"
                          @keyup.native="item.freight = numInput(item.freight)"></el-input>
              </div>
              <div class="column column05">
                <el-input placeholder="请输入收货人" :disabled="checkStatus(item)" v-model="item.receiver">
                </el-input>
              </div>
              <div class="column column06">
                <el-input placeholder="请输入收货人电话" :disabled="checkStatus(item)"
                          v-model="item.receiverPhone"></el-input>
              </div>
              <div class="column column07">
                <el-date-picker v-model="item.deliveryDate" :disabled="checkStatus(item)" type="date"
                                value-format=“yyyy-MM-dd”
                                placeholder="请选择发出时间">
                </el-date-picker>
              </div>
              <div class="column column08">
                <el-select v-model="item.addressId" :disabled="checkStatus(item)" filterable
                           placeholder="请选择" @change="changeAddress">
                  <el-option v-for="item in addressList" :key="item.id" :label="item.value"
                             :value="item.id">
                  </el-option>
                </el-select>
                <!-- <el-autocomplete v-model="item.address" :fetch-suggestions="querySearchAsync"
                  placeholder="请输入收货地址"></el-autocomplete> -->
              </div>
              <div class="column column09">
                <el-select v-model="item.logisticsCompanyId" filterable :disabled="checkStatus(item)"
                           placeholder="请选择">
                  <el-option v-for="item in logisticsCompanyOptions" :key="item.id" :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </div>

              <div class="column column11">
                <el-select v-model="item.consignorId" filterable :disabled="checkStatus(item)"
                           placeholder="请选择">
                  <el-option v-for="item in consignorOptions" :key="item.id" :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="column column12">
                <el-select v-model="item.isVisit" :disabled="checkStatus(item)" placeholder="请选择">
                  <el-option v-for="item in deliverOptions" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="column column13">
                <el-button type="primary" size="mini" @click="imgUpload(item)">点击上传</el-button>
              </div>
              <div class="column column15">
                <el-button title="添加" class="add-btn" type="primary" size="mini" icon="el-icon-plus"
                           circle v-if="index==0" @click="expandAddClick(row,index)"></el-button>
                <el-button title="删除" class="delete-btn" type="danger" size="mini" icon="el-icon-delete"
                           circle v-if="index!=0 && item.status !=2" @click="expandDelClick(row,index)">
                </el-button>
                <el-button title="复制" type="warning" size="mini" icon="el-icon-document-copy" circle
                           @click="copyClick(item)"></el-button>
                <el-button title="插入" v-if="item.status !== 2" type="success" size="mini"
                           icon="el-icon-back" circle @click="pasteClick(row,index)"></el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 上传照片弹窗 -->
    <el-dialog title="物流单照片" :visible.sync="dialogFormVisible">
      <el-form class="upload-img" label-width="80px">
        <el-form-item label="上传照片:">
          <el-button type="primary" @click="getVideo">打开摄像头</el-button>
          <el-button type="primary" @click="getImage">拍照</el-button>
          <el-upload
              class="upload-demo"
              action="/api/admin/upload"
              :on-success="onSuccess"
              :on-remove="onRemove"
              :headers="headers"
              :show-file-list="false"
              list-type="picture">
            <el-button size="small" type="primary">本地上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
          <!-- 					<el-button type="warning" @click="closeVideo">关闭摄像头</el-button>
 -->
          <div class="photograph">
            <video id="video"></video>
            <canvas id="canvas"></canvas>
          </div>
          <div class="img-list">
            <div class="img-list-item" v-for="(url,index) in imgUrlList" :key="url">
              <el-image :src="url" :preview-src-list="imgUrlList"></el-image>
              <i class="el-icon-error" @click="deleteImg(index)"></i>
            </div>
          </div>
          <img id="img"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
                   :page-size="query.pagesize" @current-change="getList"></el-pagination>
    <!-- 图片预览弹窗 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
      <div class="picking-header">
        <div class="picking-title">提货通知单号：{{ pickingData.orderNum }}</div>
        <div class="picking-title">总价：{{ pickingData.totalAmount }}元</div>
      </div>
      <el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
                highlight-current-row>
        <el-table-column property="sparePartCode" label="备件代码"></el-table-column>
        <el-table-column property="sparePartName" label="备件名称"></el-table-column>
        <el-table-column property="num" label="数量"></el-table-column>
        <el-table-column property="costPrice" label="单价"></el-table-column>
        <el-table-column property="amount" label="金额"></el-table-column>
        >
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  regionDataPlus
} from 'element-china-area-data'
import {
  fetchList,
  updateInvoice,
  deleteInvoice,
  upload,
  selectSparePartsType,
  getById,
  selectAddress
} from "@/api/invoice";
import {
  selectList as selectConsignorList
} from "@/api/consignor"
import {
  selectList as selectLogisticsCompanyList
} from "@/api/logisticsCompany"
import {
  v4 as uuidv4
} from 'uuid';

export default {
  props: ['searchInput', 'refresh'],
  data() {
    return {
      loading: false,
      screenTime: '',
      pickingData: "",
      dialogTableVisible: false,
      action: '/api/admin/upload',
      typeList: [],
      dialogFormVisible: false,
      dialogImageUrl: '',
      dialogVisible: false,
      imgUrlList: [],
      regionOptions: regionDataPlus,
      billingMethodVal: 1,
      copyItem: null,
      headers: {
        token: localStorage.getItem('token')
      },
      billingMethodOptions: [{
        value: 1,
        label: '重量'
      }, {
        value: 2,
        label: '体积'
      }, {
        value: 3,
        label: '发货件数'
      }, {
        value: 4,
        label: '重抛'
      }, {
        value: 5,
        label: '整车'
      }],
      deliverOptions: [{
        value: 1,
        label: '是'
      }, {
        value: 2,
        label: '否'
      }],
      consignorOptions: [],
      freightOptions: [{
        value: 1,
        label: '是'
      }, {
        value: 2,
        label: '否'
      }],
      logisticsCompanyOptions: [],
      tableData: [],
      sparePartsTypes: [],
      total: 10,
      query: {
        status: -1,
        keyword: '',
        screenTime: '',
        page: 1,
        pagesize: 10,
        sortProp: '',
        sortOrder: '',
        sparePartsTypes: []
      },
      activeRow: null,
      addressList: [],
      fileList: [],

    }
  },
  watch: {
    refresh() {
      if (this.refresh == "-1") {
        this.getList()
      }
    },
    searchInput() {
      this.query.page = 1
      this.query.keyword = this.searchInput
      this.getList()
    },
    sparePartsTypes() {
      this.query.page = 1
      this.query.sparePartsTypes = this.sparePartsTypes
      this.getList()
    },
    screenTime() {
      this.query.page = 1
      this.query.entryDate = this.screenTime
      this.getList()
    }
  },
  mounted() {
    this.getList()
    this.selectSparePartsType()
    this.getConsignorList()
    this.getLogisticsCompanyList()
    this.getAddressList()
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onSuccess(response, file, fileList) {
      this.imgUrlList.push(response.data.url)
      this.activeRow.imgList.push(response.data)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({row, rowIndex}) {
      if (row.notPassReason !== null && row.notPassReason !== '') {
        return 'error-row';
      }
      return '';

    },
    onRemove(file, fileList) {
      console.log(file, fileList);
      const i = this.imgUrlList.indexOf(file.response.data.url)
      this.imgUrlList.splice(i, 1)
      const index = this.activeRow.imgList.indexOf(file.response.data)
      this.activeRow.imgList.splice(index, 1)
    },
    changeAddress(item) {
      console.log(item)
    },
    getAddressList() {
      selectAddress().then(res => {
        if (res.code == 0) {
          this.addressList = res.data
        }
      })

    },
    screenTimeChange() {
      this.query.entryDate = this.screenTime
      this.getList()
    },
    pickingDetails(row) {
      this.dialogTableVisible = true
      this.pickingData = row
    },
    numInput(num) {
      var str = num
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1)
      }
      //第一位不能是.
      if (len1 == ".") {
        str = ""
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1)
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
        }
      }
      //正则替换
      str = str.replace(/[^\d^.]+/g, '') // 保留数字和小数点
      return str
    },
    deleteImg(index) {
      this.imgUrlList.splice(index, 1)
      this.activeRow.imgList.splice(index, 1)
    },
    selectSparePartsType() {
      selectSparePartsType(this.query.status).then(res => {
        this.typeList = res.data
      })
    },
    checkStatus(item) {
      if (item.status === 2) {
        return true
      } else {
        return false
      }
    },
    getConsignorList() {
      selectConsignorList().then(res => {
        this.consignorOptions = res.data
      })
    },
    getLogisticsCompanyList() {
      selectLogisticsCompanyList().then(res => {
        this.logisticsCompanyOptions = res.data

      })
    },
    getVideo() {
      var video = document.getElementById("video");
      const loading = this.$loading({
        lock: true,
        text: '正在加载摄像头...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: "main-loading"
      });
      navigator.mediaDevices.getUserMedia({
        video: true
      }).then(function (stream) {
        video.srcObject = stream;
        video.play();
        loading.close();
      }).catch(function (err) {
        console.log(err);
      });
    },
    //对video进行截图
    getImage() {
      var video = document.getElementById("video");
      var width = video.clientWidth;
      var height = video.clientHeight;
      var canvas = document.getElementById("canvas");
      canvas.height = height
      canvas.width = width
      var context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, width, height);
      var imgData = canvas.toDataURL("image/png");
      // var img = document.getElementById("img");
      // img.src = imgData;
      let fileName = uuidv4()
      const file = this.dataURLtoFile(imgData, fileName + ".png")
      let form = new FormData();
      form.append('file', file);
      upload(form).then(res => {
        if (res.code === 0) {
          this.imgUrlList.push(res.data.url)
          this.activeRow.imgList.push(res.data)

        }
      })

    },
    dataURLtoFile(dataurl, filename) { //将base64转换为文件
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    getList() {
      this.loading = true
      fetchList(this.query).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    Filtertype(value, row, column) {
      this.sparePartsTypes = column.filteredValue
      return row.sparePartsType === value;
    },
    imgChange(uploadFile, fileList) {
      this.ruleForm.imgList = uploadFile
      if (fileList.length !== 0) {
        this.$refs.ruleForm.validateField('imgList')
      }

    },
    imgRemove(uploadFile, fileList) {
      if (fileList.length === 0) {
        this.ruleForm.imgList = null
        this.$refs.ruleForm.validateField('imgList')
      }
    },
    submitForm() {
      this.dialogFormVisible = false
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    imgUpload(item) {
      this.activeRow = item
      if (this.activeRow.imgList === null) {
        this.activeRow.imgList = []
        this.imgUrlList = []
      } else {
        this.imgUrlList = this.activeRow.imgList.map(e => e.url)
      }

      this.dialogFormVisible = true
    },
    copyClick(item) {
      if (item.id !== 0) {
        item.id == 0
      }
      this.copyItem = JSON.stringify(item)
    },
    expandAddClick(row) {
      row.logisticsInformations.push({
        trackingNumber: '',
        weight: '',
        volume: '',
        num: 1,
        freight: '',
        receiver: "",
        receiverPhone: "",
        deliveryDate: "",
        logisticsCompanyId: '',
        isFree: 2,
        addressId: '',
        consignorId: '',
        isVisit: 2,
        billingMethod: '',
        imgList: []
      })
    },
    pasteClick(row, index) {
      if (this.copyItem != null) {
        row.logisticsInformations.splice(index, 1, Object.assign({}, JSON.parse(this.copyItem)))
        row.logisticsInformations[index].id = null
        row.logisticsInformations[index].status = -1
      }

    },
    expandDelClick(row, index) {
      row.logisticsInformations.splice(index, 1)
    },
    handleChange(value) {
      console.log(value)
      // 楼主选择江苏省南京市江宁区 打印value结果 ["320000", "320100", "320114"]
    },
    // 展开
    toogleExpand(row) {
      let $editTable = this.$refs.editTable;
      $editTable.toggleRowExpansion(row)
      if (row.logisticsInformations.length === 0 || row.logisticsInformations === null) {
        this.expandAddClick(row)
      }

      // $table.toggleRowExpansion(row, true) // 点击button展开
    },
    examineClick(row) {
      let rowInfo = row.logisticsInformations
      if (rowInfo.length <= 0) {
        this.$message({
          type: "error",
          message: "请完整填写物流单信息!",
        });
        return
      }
      for (let i in rowInfo) {
        if (rowInfo[i].isFree === 2) {
          if (rowInfo[i].logisticsCompanyId === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行物流公司!`,
            });
            return
          }
          if (rowInfo[i].trackingNumber === "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行运单号!`,
            });
            return
          }
          if (rowInfo[i].deliveryDate === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行发出时间!`,
            });
            return
          }
          let deliveryDate = rowInfo[i].deliveryDate + ""
          let dateStrs = deliveryDate.replace('“', '').replace('”', '')
          rowInfo[i].deliveryDate = dateStrs
          if (rowInfo[i].consignorId === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行发货员!`,
            });
            return
          }
        } else {


          let billingMethod = rowInfo[i].billingMethod
          if (rowInfo[i].trackingNumber === "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行运单号!`,
            });
            return
          }
          if (billingMethod === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行计费方式!`,
            });
            return
          } else if (billingMethod == 1 && rowInfo[i].weight == "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行重量!`,
            });
            return
          } else if (billingMethod == 2 && rowInfo[i].volume == "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行体积!`,
            });
            return
          } else if (billingMethod == 4 && rowInfo[i].weight == "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行重量!`,
            });
            return
          } else if (billingMethod == 4 && rowInfo[i].volume == "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行体积!`,
            });
            return
          }

          if (rowInfo[i].freight === "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行运费!`,
            });
            return
          } else if (rowInfo[i].freight !== "0") {
            if (billingMethod == 1 && rowInfo[i].weight === "0") {
              this.$message({
                type: "error",
                message: `运费不为0的情况下，第${Number(i) + 1}行重量不能为0!`,
              });
              return
            }
            if (billingMethod == 2 && rowInfo[i].volume === "0") {
              this.$message({
                type: "error",
                message: `运费不为0的情况下，第${Number(i) + 1}行体积不能为0!`,
              });
              return
            }
            if (billingMethod == 4) {
              if (rowInfo[i].weight === "0" || rowInfo[i].volume === "0") {
                this.$message({
                  type: "error",
                  message: `运费不为0的情况下，第${Number(i) + 1}行重量和体积不能为0!`,
                });
                return
              }
            }
          }
          if (rowInfo[i].receiver === "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行收货人!`,
            });
            return
          }
          if (rowInfo[i].receiverPhone === "") {
            this.$message({
              type: "error",
              message: `请填写第${Number(i) + 1}行收货人电话!`,
            });
            return
          }
          if (rowInfo[i].deliveryDate === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行发出时间!`,
            });
            return
          }
          let deliveryDate = rowInfo[i].deliveryDate + ""
          let dateStrs = deliveryDate.replace('“', '').replace('”', '')
          rowInfo[i].deliveryDate = dateStrs
          if (rowInfo[i].addressId === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行收货地!`,
            });
            return
          }
          if (rowInfo[i].logisticsCompanyId === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行物流公司!`,
            });
            return
          }
          if (rowInfo[i].consignorId === "") {
            this.$message({
              type: "error",
              message: `请选择第${Number(i) + 1}行发货员!`,
            });
            return
          }

          if (rowInfo[i].imgList == null || rowInfo[i].imgList.length <= 0) {
            this.$message({
              type: "error",
              message: `请上传第${Number(i) + 1}行物流单照片!`,
            });
            return
          }

        }
        rowInfo[i].address = this.addressList.filter(e => e.id ==
            rowInfo[i].addressId)[0].value
      }
      this.$confirm("确定要提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            getById(row.id).then(res => {
              if (res.code == 0) {
                if (JSON.stringify(row).trim() === JSON.stringify(res.data).trim()) {
                  this.$confirm("物流单信息未修改请确认是否提交审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                      .then(() => {
                        this.commitExamin(row)
                      })
                } else {
                  this.commitExamin(row)
                }
              }
            })
          })
          .catch(() => {
          });
    },
    commitExamin(row) {
      console.log(row)
      row.status = 1
      updateInvoice(row).then(res => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.getList()
        }

      })
    },
    deleteClick(row, index) {
      this.$confirm("确定要永久删除该行数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.tableData.splice(index, 1)
            deleteInvoice(row.id).then(res => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList()
              }

            })

          })
          .catch(() => {
          });
    },
    handleSortChange(column) {
      this.query.sortProp = "spare_parts_type"
      this.query.sortOrder = column.order
      this.getList()
    },
  }
}
</script>

<style lang="scss">
.el-table .error-row {
  background: #f6fc51;
}

.main-loading {
  color: #FFF;

  .el-icon-loading {
    color: #FFF;
    font-size: 30px;
  }

  .el-loading-text {
    color: #FFF;
    font-size: 16px;
  }
}
</style>
