import request from '@/utils/request'

export function fetchList(data) {
    return request({
        url: '/admin/invoice/list',
        method: 'post',
        data
    })
}

export function updateInvoice(data) {
    return request({
        url: '/admin/invoice/update',
        method: 'post',
        data
    })
}

export function appendTracking(id) {
  return request({
    url: '/admin/invoice/appendTracking',
    method: 'post',
    params: { id }
  })
}

export function deApproval(id) {
  return request({
    url: '/admin/invoice/deApproval',
    method: 'post',
    params: { id }
  })
}

export function selectSparePartsType(status) {
  return request({
    url: '/admin/invoice/selectSparePartsType',
    method: 'get',
	params: { status }
  })
}

export function selectAddress() {
  return request({
    url: '/admin/invoice/selectAddress',
    method: 'get'
  })
}

export function deleteInvoice(id) {
  return request({
    url: '/admin/invoice/delete',
    method: 'get',
    params: { id }
  })
}
export function revoke(id) {
  return request({
    url: '/admin/invoice/revoke',
    method: 'get',
    params: { id }
  })
}

export function audit(data) {
  return request({
    url: '/admin/invoice/audit',
    method: 'post',
    data
  })
}

export function upload(data) {
  return request({
    url: '/admin/upload',
    method: 'post',
    data
  })
}

export function addInvoice(data) {
    return request({
        url: '/admin/invoice/add',
        method: 'post',
        data
    })
}

export function getById(id) {
  return request({
    url: '/admin/invoice/getById',
    method: 'get',
    params: { id }
  })
}
