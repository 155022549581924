<template>
  <div class="pass-table">
    <div class="table-wrapper">
      <el-table class="main-table" :data="tableData" border header-row-class-name="table-header"
                highlight-current-row ref="filterTable" v-loading="loading">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="序号" type="index" align="center" width="80" fixed></el-table-column>
        <el-table-column label="地区" prop="region" align="center"></el-table-column>
        <el-table-column label="类型" prop="sparePartsTypeName" align="center" width="120"></el-table-column>
        <el-table-column label="导入日期" prop="orderEntryDate" align="center" width="110" fixed></el-table-column>
        <el-table-column label="录入日期" prop="entryDate" align="center" width="110" fixed></el-table-column>
        <el-table-column label="提货单编号" prop="orderNum" align="center" width="150" fixed></el-table-column>
        <el-table-column label="单位简称" prop="unitName" align="center" width="120"></el-table-column>
        <el-table-column label="提货金额（元）" prop="totalAmount" align="center" width="130"></el-table-column>
        <el-table-column label="提货件数" prop="totalNum" align="center" width="150">
          <template slot-scope="{row}">
            {{ row.totalNum }}
            <el-button type="primary" size="mini" round @click="pickingDetails(row)">提货明细</el-button>
          </template>
        </el-table-column>
        <el-table-column label="不通过原因" prop="notPassReason" align="center" width="120"></el-table-column>
        <el-table-column label="运单号" prop="trackingNumber" align="center" width="140"></el-table-column>
        <el-table-column label="计费方式" prop="billingMethod" align="center" width="120">
          <template slot-scope="{row}">
            <el-tag type="info" v-if="row.billingMethod==1">重量</el-tag>
            <el-tag type="info" v-if="row.billingMethod==2">体积</el-tag>
            <el-tag type="info" v-if="row.billingMethod==3">发货件数</el-tag>
            <el-tag type="info" v-if="row.billingMethod==4">重抛</el-tag>
            <el-tag type="info" v-if="row.billingMethod==5">整车</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货件数" prop="num" align="center" width="140"></el-table-column>
        <el-table-column label="重量（kg）" prop="weight" align="center" width="120"></el-table-column>
        <el-table-column label="体积（m³）" prop="volume" align="center" width="120"></el-table-column>
        <el-table-column label="运费（元）" prop="freight" align="center" width="120"></el-table-column>
        <el-table-column label="备注" prop="remark" align="center" width="120"></el-table-column>
        <el-table-column label="备注2" prop="remark2" align="center" width="120"></el-table-column>
        <el-table-column label="收货人" prop="receiver" align="center"></el-table-column>
        <el-table-column label="收货人电话" prop="receiverPhone" align="center" width="140"></el-table-column>
        <el-table-column label="发出时间" prop="deliveryDate" align="center" width="110"></el-table-column>
        <el-table-column label="收货地" prop="address" align="center" width="150"></el-table-column>
        <el-table-column label="物流公司" prop="logisticsCompanyName" align="center" width="120"></el-table-column>
        <el-table-column prop="isFree" label="免运费" width="120" align="center"
                         :filters="[{ text: '是', value: 1 }, { text: '否', value: 2 }]"
                         :filter-method="filterFreight" filter-placement="bottom-end">
          <template slot-scope="{row}">
            <el-tag v-if="row.isFree==1">是</el-tag>
            <el-tag type="danger" v-if="row.isFree==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发货员" prop="consignorName" align="center" width="120"></el-table-column>
        <el-table-column label="送货上门" prop="isVisit" align="center" width="140"
                         :filters="[{ text: '是', value: 1 }, { text: '否', value: 2 }]"
                         :filter-method="filterDeliver" filter-placement="bottom-end">
          <template slot-scope="{row}">
            <el-tag v-if="row.isVisit==1">是</el-tag>
            <el-tag type="danger" v-if="row.isVisit==2">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="物流单照片" prop="imgUrl" align="center" width="120">
          <template slot-scope="{row}">
            <el-image style="width:23px; height:23px" :src="row.imgUrl"
                      :preview-src-list="row.imgList.map(e => e.url)">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center" class-name="table-operate" fixed="right">
          <template slot-scope="{row, $index}">
            <el-button type="primary" size="mini" round v-if="common.canEdit()" @click="exitClick(row)">编辑</el-button>
            <el-button type="danger" size="mini" round v-if="common.canEdit()" @click="deleteClick(row, $index)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="reset" type="warning" @click="resetClick" v-if="resetShow">重置筛选</el-button>
    </div>
    <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
                   :page-size="query.pagesize" @current-change="getList"></el-pagination>
    <el-dialog title="提货通知单明细" :visible.sync="dialogTableVisible">
      <div class="picking-header">
        <div class="picking-title">提货通知单号：{{ pickingData.orderNum }}</div>
        <div class="picking-title">总价：{{ pickingData.totalAmount }}元</div>
      </div>
      <el-table :data="pickingData.invoiceDetails" class="main-table" border header-row-class-name="table-header"
                highlight-current-row>
        <el-table-column property="sparePartCode" label="备件代码"></el-table-column>
        <el-table-column property="sparePartName" label="备件名称"></el-table-column>
        <el-table-column property="num" label="数量"></el-table-column>
        <el-table-column property="costPrice" label="单价"></el-table-column>
        <el-table-column property="amount" label="金额"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import {
  fetchList,
  selectGroup,
  deleteLogisticsInformation
} from "@/api/logisticsInformation"
import {
  appendTracking
} from "@/api/invoice"

export default {
  props: ['searchInput', 'refresh'],
  data() {
    return {
      loading: false,
      resetShow: false,
      pickingData: "",
      dialogTableVisible: false,
      typeList: [{
        id: 1,
        name: '长春备品'
      },
        {
          id: 2,
          name: '青汽轻卡'
        },
        {
          id: 3,
          name: '青汽重卡'
        },
        {
          id: 4,
          name: 'DMS'
        },
        {
          id: 5,
          name: '手动录入'
        },
        {
          id: 6,
          name: '玉韦耶克'
        }
      ],
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      tableData: [],
      total: 10,
      query: {
        status: 3,
        keyword: '',
        page: 1,
        pagesize: 10,
        sort: '-entry_date,-unit_name,+order_num',
        isVisitArr: [],
        isFreeArr: []
      },
      isVisitArr: [],
      isFreeArr: [],
      isVisitArrWatch: [],
      isFreeArrWatch: [],
      webSocket: null,
      socketUrl: "ws://logistics.yuweiyeke.com/ws/" + localStorage.getItem('token')
    }
  },
  mounted() {
    this.getGroup()
    this.getList()
    this.initWebSocket()
  },
  watch: {
    isFreeArrWatch() {
      this.getList()
    },

    isVisitArrWatch() {
      this.getList()
    },
    refresh() {
      if (this.refresh === "3") {
        this.getList()
      }
    },
    searchInput() {
      this.query.page = 1
      this.query.keyword = this.searchInput
      this.getList()

    },
    tableData: {
      immediate: true,
      handler(val) {
        if (val.length == 0) {
          this.resetShow = true
        } else {
          this.resetShow = false
        }
      }
    }
  },
  destroyed() {
    if (this.webSocket != null) {
        this.webSocket.close()
    }
  },
  methods: {
    initWebSocket() {
      let that = this
      if ("WebSocket" in window) {
        this.webSocket = new WebSocket(this.socketUrl);
      } else {
        alert("当前浏览器 Not support websocket");
      }
      //连接成功建立的回调方法
      this.webSocket.onopen = function () {
        console.log("WebSocket连接成功");
      };
      //连接发生错误的回调方法
      this.webSocket.onerror = function (e) {
        console.log(e);
        console.log("WebSocket连接发生错误");
      };
      this.webSocket.onmessage = function (event) {
        const data = event.data

        // 忽略心跳消息
        if (data === 'pong') {
          return;
        }
        if (data === 'update'){
          that.getList()
        }

      }
    },
    resetClick() {
      this.query = {
        status: 3,
        keyword: '',
        page: 1,
        pagesize: 10,
        isVisitArr: [],
        isFreeArr: []
      },
          this.$refs.filterTable.clearFilter();
      this.getList()
      this.resetShow = false
    },
    getGroup() {
      selectGroup(this.query.status, 'is_visit').then(res => {
        this.isVisitArr = res.data
        this.query.isVisitArr = this.isVisitArr.map(e => e.value)
      })
      selectGroup(this.query.status, 'is_free').then(res => {
        this.isFreeArr = res.data
        this.query.isFreeArr = this.isFreeArr.map(e => e.value)
      })
    },
    pickingDetails(row) {
      this.dialogTableVisible = true
      this.pickingData = row
    },
    exitClick(row) {
      appendTracking(row.invoiceId).then(res => {
        if (res.code === 0) {
          this.$emit('appendTracking', row.orderNum)
        }
      })
    },
    getList() {
      this.loading = true
      fetchList(this.query).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
        this.$emit('total',this.total)
        this.loading = false
      })
    },

    filterDeliver(value, row, column) {
      this.query.isVisitArr = column.filteredValue
      this.isVisitArrWatch = column.filteredValue
      return row.isVisit === value;
    },
    filterFreight(value, row, column) {
      this.query.isFreeArr = column.filteredValue
      this.isFreeArrWatch = column.filteredValue
      return row.isFree === value;
    },
    oneExamine() {
      this.$confirm("确定要全部审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "审核通过!",
            });
          })
          .catch(() => {
          });
    },
    examineClick() {
      this.$confirm("请确定审核是否通过?", "提示", {
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
          .then(() => {
            this.$message({
              type: "success",
              message: "审核通过!",
            });
          })
          .catch(() => {
            this.$prompt('请输入不通过原因', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /\S/,
              inputErrorMessage: '请输入不通过原因'
            }).then(() => {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
            }).catch(() => {
              // this.$message({
              // 	type: 'info',
              // 	message: '取消输入'
              // });
            });
          });
    },
    deleteClick(row, index) {
      this.$confirm("确定要永久删除该行数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.tableData.splice(index, 1)
            deleteLogisticsInformation(row.id).then(res => {
              if (res.code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList()
              }
            })

          })
          .catch(() => {
          });
    },
  }
}
</script>

<style lang="scss" scoped>
.pass-table {
  .bottom {
    background-color: rgba($color: $main-color, $alpha: 0.1);
    padding: 5px 10px;
    text-align: right;

    .examine-btn {
      border-radius: 5px;
      font-size: 16px;
    }
  }

}
</style>
